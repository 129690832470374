@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Bowlby+One+SC&family=Rubik+Mono+One&display=swap');

.rubik-mono-one-regular {
    font-family: "Rubik Mono One", monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  .bowlby-one-regular {
    font-family: "Bowlby One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
    
  .bowlby-one-sc-regular {
    font-family: "Bowlby One SC", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  